<template>

    <div class="add-user-form p-p-3">
        <h2 v-if="!userid">Add User</h2>
        <h2 v-else>Edit User</h2>
        <form autocomplete="off">
        <div class="p-field p-grid">
            <label for="email" :class="['p-col',{'p-error':errors.Email}]" style="width:100px">Email</label>
            <div class="p-col">
                <InputText id="email" :class="{'p-invalid':errors.Email}" type="text" v-model="data.Email"/>
            </div>
        </div>
        <div class="p-field p-grid">
            <label for="displayname" class='p-col' style="width:100px">Display Name</label>
            <div class="p-col">
                <InputText id="displayname" type="text" v-model="data.DisplayName"/>
            </div>
        </div>
        <div class="p-field p-grid">
            <label :class="['p-col',{'p-error':errors.Password}]" style="width:100px">Password</label>
            <div class="p-col">
                <InputText autocomplete="off" :class="{'p-invalid':errors.Password}" type="password" v-model="data.Password"/>
            </div>
        </div>
        <div class="p-field p-grid">
            <label :class="['p-col',{'p-error':errors.PasswordConfirm}]" style="width:100px">Password Confirm</label>
            <div class="p-col">
                <InputText autocomplete="off" :class="{'p-invalid':errors.PasswordConfirm}" type="password" v-model="data.PasswordConfirm"/>
            </div>
        </div>

        <div class="p-field p-grid" v-if="getUser().Superuser">
            <label for="superuser" class="p-col" style="width:100px">Superuser</label>
            <div class="p-col">
                <InputSwitch id="password2" v-model="data.Superuser"/>
            </div>
        </div>

        <div class="p-field p-grid">
            <label style="align-items: initial; width: 100%" for="accounts" class="p-col">Assigned Accounts</label>
            <div class="p-col">
                <Listbox v-model="data.Accounts" :options="Accounts" optionValue="Account" :multiple="true"
                         :filter="true"
                         listStyle="max-height:250px" :filterFields="['Account','Host']">
                    <template #option="s">
                        {{ s.option.Account }} - {{ s.option.Host }}
                    </template>
                </Listbox>
            </div>
        </div>

        <Button v-if="!userid" label="Create user" @click="doSave"></Button>
        <Button v-else label="Save user" @click="doSave"></Button>
        </form>
    </div>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';
import Listbox from 'primevue/listbox';
import MetricService from "@/services/metrics";
import UACService from "@/services/uac"

export default {
    props: {
        userid: String,
    },
    name: "AddEditUser",
    components: {InputSwitch, Listbox,},
    metricService: null,
    uacService: null,
    data: function () {
        return {
            errors: {
                Email: false,
                Password: false,
                PasswordConfirm: false,
            },
            searchAccount: '',
            data: {
                Email: null,
                DisplayName: null,
                Password: '',
                PasswordConfirm: '',
                Superuser: null,
                Accounts: [],
            },
            Accounts: []
        }
    },
    created() {

    },
    mounted() {
        this.metricService = new MetricService()
        this.uacService = new UACService()
        this.metricService.getAccounts().then(res => {
            this.Accounts = res;
        })

        if (this.userid !== undefined) {
            this.uacService.getUser(this.userid).then(res => {
                this.data.Superuser = res.Superuser
                this.data.Email = res.Email
                this.data.DisplayName = res.DisplayName
                this.data.Accounts = res.Accounts
            })
        }
    },
    methods: {
        checkErrors: function() {
            this.errors.Email = false
            this.errors.Password = false
            this.errors.PasswordConfirm = false

            this.errors.Email = !this.data.Email
            if (this.userid === undefined) {
                this.errors.Password = this.data.Password.length === 0
                this.errors.PasswordConfirm = this.data.PasswordConfirm.length === 0
            }

            console.log(this.data.Password, this.data.PasswordConfirm, this.data.Password !== this.data.PasswordConfirm)
            if (this.data.Password !== this.data.PasswordConfirm) {
                this.errors.Password = true
                this.errors.PasswordConfirm = true
            }

            let result = true;
            Object.values(this.errors).forEach(t => {
                if (t === true) result = false
            })

            return result
        },
        doSave: function () {
            if (this.checkErrors()) {
                this.uacService.saveUser(this.data, this.userid).then(res => {
                    if (res.message === 'ok') {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success Message',
                            detail: (this.userid===undefined)?'User created':"User saved",
                            life: 2000
                        });
                        this.$router.push({name: "list-users"})
                    } else {
                        this.$toast.add({severity: 'error', summary: 'Error Message', detail: res.message, life: 3000});
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.add-user-form {
    font-size: 12pt;
    width: 70%;
}

.add-user-form .p-inputtext {
    width: 100%;
}

.add-user-form .p-multiselect {
    width: 100%
}

.search-block {
    margin-bottom: 10px;
}

.accounts-list {
    overflow: auto;
    height: 300px;
}

.acc-inp {
    margin-top: 10px;
    display: inline-block;
    width: 100px
}

.acc-inp label {
    padding-left: 5px;
}
</style>